import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import { useSelector } from "react-redux";
import Suscripcion from "./Suscripcion";
import { SUBSCRIPTION } from "store/constants";

const TabTareas = () => {
	const data = useSelector(state => state[SUBSCRIPTION.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Nombre",
				accessor: "nombre"
			}
		],
		[]
	);

	return (
		<>
			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={SUBSCRIPTION}
				modal={Suscripcion}
				filters={columns}
				options={{ orderBy: true }}
			/>
		</>
	);
};

export default TabTareas;
