import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import { useSelector } from "react-redux";
import HomeModal from "./Home";
import {  HOME } from "store/constants";
import Header from "../../components/Header/Header";


const TabTareas = () => {
	const data = useSelector(state => state[HOME.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Nombre seccion",
				accessor: "nombre_seccion"
			},
			{
				Header: "Orden",
				accessor: "orden"
			}
		],
		[]
	);

	return (
		<>
			<Header title="Pantalla inicio" video time="5:35" />

			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={HOME}
				modal={HomeModal}
				filters={columns}
				options={{ orderBy: true}}
			/>
		</>
	);
};

export default TabTareas;
