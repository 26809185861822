import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";

const Categoria = props => {    
	const elements = [
		{
			type: "text",
			name: "nombre",
			label: "Nombre",
		},
		{
			type: "text",
			name: "duracion",
			label: "Duracion",
		},
		{
			type: "select",
			name: "tipo",
			label: "Tipo",
			options:[
				{value:"video",label:"Video"},
				{value:"audio",label:"Audio"},
				{value:"pdf",label:"PDF"},
				{value:"texto",label:"Texto"},
				{value:"live",label:"Live"},
			]
		},
		{
			type: "if",
			name: ["tipo","link_live"],
			formula: tipo=>tipo==="live",
			field:{
				type: "text",
				name: "link_live",
				label: "Link live",
			}
		},
		{
			type: "if",
			name: ["tipo","embed_live"],
			formula: tipo=>tipo==="live",
			field:{
				type: "text",
				name: "embed_live",
				label: "Embed Live",
			}
		},	
		{
			type: "file",
			name: "archivos_sin_edicion",
			label: "Archivos sin edicion",
			accept:"video/*,audio/*,application/pdf",
			multipleFiles: true
		},	
		{
			type: "file",
			name: "archivo",
			label: "Archivo editado",
			accept:"video/*,audio/*,application/pdf",
		},
	];
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data} onFile={props.onFile} removeFile={props.removeFile}></ModalForm>
		</>
	);
};

export default Categoria;
