import React, {useState} from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";

const Categoria = props => {  
	const elements = [
		{
			type: "text",
			name: "pregunta",
			label: "Pregunta",
		},
		{
			type: "text",
			name: "respuesta",
			label: "Respuesta",
		},
	];


	return (
		<>		
			<ModalForm elements={elements} onChange={props.onChange} data={props.data} onFile={props.onFile}>
				
			</ModalForm>
		</>
	);
};

export default Categoria;
