import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import { DISCOUNT, COURSE } from "store/constants";
import { useSelector } from "react-redux";

// codigo: Joi.string(),
// nombre: Joi.string(),
// descripcion: Joi.string(),
// // precio: Joi.number(),
// // precio_descuento: Joi.number(),
// appstore_sku: Joi.string(),
// appstore_sku_suscriptores: Joi.string(),
// cursos:Joi.array().items(Joi.string()),
// fecha_inicio:Joi.date().iso(),
// fecha_fin:Joi.date().iso(),
// max_usos:Joi.number(),
// max_usos_cliente:Joi.number(),
// solo_clientes_nuevos: Joi.boolean(),
// automatico:Joi.boolean(),
// descuento:Joi.number(),
// p_descuento:Joi.number(),

const Categoria = props => {    
	const elements = [
		{
			type: "text",
			name: "nombre",
			label: "Nombre",
		},
		{
			type: "text",
			name: "codigo",
			label: "Codigo",
		},
		{
			type: "multiSelect",
			selector:(state)=>state.COURSE.data.filter((r)=>!r._trash),
			name: "cursos",
			label: "Cursos que aplica",
			// modal:ClaseModal,
			datatype:COURSE,
			columns:[
				{ Header: "Nombre", accessor: "nombre"}
			]
		},
		{
			type: "calendar",
			name: "fecha_inicio",
			label: "Fecha inicio",
		},
		{
			type: "calendar",
			name: "fecha_fin",
			label: "Fecha fin",
		},
		{
			type: "text",
			subtype:"number",
			name: "max_usos",
			label: "Max num usos",
		},
		{
			type: "text",
			subtype:"number",
			name: "max_usos_cliente",
			label: "Max num usos por cliente",
		},
		{
			type: "bool",
			name: "solo_clientes_nuevos",
			label: "Solo clientes nuevos",
		},
		{
			type: "bool",
			name: "automatico",
			label: "Aplica automaticamente",
		},
		{
			type: "text",
			subtype:"number",
			name: "descuento",
			label: "$ descuento",
		},
		{
			type: "text",
			subtype:"number",
			name: "p_descuento",
			label: "% descuento",
		},
	];

	// const {data, onChange, onFile, remove, save, status, isValid, setValid} = useSave({type:AUTHOR,data:sData, autoSave:true, fileUrl:"foto"});
	// console.log({sData,data})

	return (
		<>
			{/* <Header title="Bio" video time="5:35" /> */}
		
			{/* <AutoSaveStatus status={status} save={save} /> */}

			<ModalForm elements={elements} onChange={props.onChange} data={props.data}></ModalForm>
		</>
	);
};

export default Categoria;
