import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { DISCOUNT } from "../constants";

const loadDiscounts = loadRequest(DISCOUNT);
const insertDiscount = insertRequest(DISCOUNT);
const updateDiscount = updateRequest(DISCOUNT);
const trashDiscount = trashRequest(DISCOUNT);
const untrashDiscount = untrashRequest(DISCOUNT);
const removeDiscount = removeRequest(DISCOUNT);

function* rootSaga() {
	yield all([
		loadDiscounts(),
		insertDiscount(),
		updateDiscount(),
		removeDiscount(),
		trashDiscount(),
		untrashDiscount()
	]);
}
export default rootSaga;