const axios = require("axios");

let url;
// if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"){
// 	url = "http://localhost:3000";
// }else{
// 	url = "https://api.nutria.com.mx";
// }
url = process.env.REACT_APP_API_URL;
let  instance = axios.create({
	baseURL: url,
	timeout: 60000,
});

export function init(token){
	instance.defaults.headers["Authorization"] = `Bearer ${token}`;
}
const token = window.localStorage.getItem("plena:admin");
export const Authorization = `Bearer ${token}`;
export function get(uri){
	return new Promise((resolve, reject)=>{
		instance.get(uri).then(function (response) {
			resolve(response);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject(error.response?.data.error || error.message);
		});
	});
}
export function post(uri, data){
	return new Promise((resolve, reject)=>{
		instance.post(uri, data).then(function (response) {
			resolve(response);
		}).catch(error=>{
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject(error.response?.data.error || error.message);
		});
	});
}
export function put(uri, data){
	return new Promise((resolve, reject)=>{
		console.log(uri,data);
		instance.put(uri, data).then(function (response) {
			resolve(response);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject(error.response?.data.error || error.message);
		});
	});
}
export function remove(uri, data){
	return new Promise((resolve, reject)=>{
		instance.delete(uri, data).then(function (response) {
			resolve(response);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject(error.response?.data.error || error.message);
		});
	});
}
export function upload(uri, files, progressCb){
	return new Promise((resolve, reject)=>{
		// const url = 'http://localhost:3000/patients/img';
		const formData = new FormData();
		if(Array.isArray(files)){
			files.forEach(f => {
				formData.append(f.name,f.file);
			});
		}else{
			formData.append(files.name,files.file);
		}
		const config = {
			headers: {
				"content-type": "multipart/form-data"
			},
			onUploadProgress: function(progressEvent) {
				var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				console.log(progressEvent);
				console.log(percentCompleted);
				progressCb(percentCompleted);
			},
			timeout:18000000
		};
		instance.post(uri, formData, config).then(function (response) {
			resolve(response);
		}).catch(function (error){
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject(error.response?.data.error || error.message);
		});
	}); 
}
export default {post, get, put, remove, upload, init};
