import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { TASK } from "../constants";

const loadTasks = loadRequest(TASK);
const insertTask = insertRequest(TASK);
const updateTask = updateRequest(TASK);
const trashTask = trashRequest(TASK);
const untrashTask = untrashRequest(TASK);
const removeTask = removeRequest(TASK);

function* rootSaga() {
	yield all([
		loadTasks(),
		insertTask(),
		updateTask(),
		removeTask(),
		trashTask(),
		untrashTask()
	]);
}
export default rootSaga;