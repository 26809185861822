import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import { DISCOUNT, COURSE } from "store/constants";
import { useSelector } from "react-redux";



// id_usuario:Joi.string(),
// 	id_curso:Joi.string(),
// 	pago:Joi.object({
// 		referido_por:Joi.string(),
// 		id_descuento:Joi.string(),
// 		fee_pagado:Joi.number(),//comision de apple o stripe
// 		descuento:Joi.number(),
// 		descuento_suscripcion:Joi.number(),
// 		subtotal:Joi.number(),//total del curso, previo a descuentos
// 		total:Joi.number(),//total pagado, no esta restado el fee, si resta descuentos
// 		pago_pais:Joi.string(),
// 		pagado_suscripcion: Joi.boolean(),//se pago con suscripcion?
// 		fecha_pago:Joi.date().iso(),
// 		id_transaccion: Joi.string(),
// 		pagado_a_autor:Joi.boolean(),

// 		tipo:Joi.string().allow("paypal","stripe"),
// 		status:Joi.string().allow("pendiente","pagado","devuelto"),

const Categoria = props => {    
	const elements = [
		{
			type: "ref",
			selector:(state)=>state.USER.data.filter((r)=>!r._trash),
			name: "id_usuario",
			label: "Usuario",
		},
		{
			type: "ref",
			selector:(state)=>state.COURSE.data.filter((r)=>!r._trash),
			name: "id_curso",
			label: "curso",
		},
		{
			type: "text",
			subtype:"number",
			name: "pago.subtotal",
			label: "Subtotal",
		},
		{
			type: "text",
			subtype:"number",
			name: "pago.total",
			label: "Total",
		},
		{
			type: "select",
			name: "pago.status",
			label: "Status",
			options:[
				{value:"pagado",label:"Pagado"},
				{value:"devuelto",label:"Devuelto"},
				{value:"pendiente",label:"Pendiente"},
			]
		},
		{
			type: "select",
			name: "pago.tipo",
			label: "Tipo",
			options:[
				{value:"transferencia",label:"Transferencia"},
				{value:"paypal",label:"PayPal"},
				{value:"openpay",label:"OpenPay"},
			]
		},
		{
			type: "calendar",
			name: "pago.fecha_pago",
			label: "Fecha pago"
		},
		{
			type: "text",
			name: "pago.id_transaccion",
			label: "ID Transaccion",
		},
		{
			type: "text",
			name: "pago.pago_pais",
			label: "Codigo pais",
		},
		{
			type: "text",
			name: "pago.fee_pagado",
			label: "Fee pagado",
		},
		
		
		
	];

	// const {data, onChange, onFile, remove, save, status, isValid, setValid} = useSave({type:AUTHOR,data:sData, autoSave:true, fileUrl:"foto"});
	// console.log({sData,data})

	return (
		<>
			{/* <Header title="Bio" video time="5:35" /> */}
		
			{/* <AutoSaveStatus status={status} save={save} /> */}

			<ModalForm elements={elements} onChange={props.onChange} data={props.data}></ModalForm>
		</>
	);
};

export default Categoria;
