import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";

const Categoria = props => {    
	const elements = [
		{
			type: "text",
			name: "nombre",
			label: "Nombre",
		},
		{
			type: "file",
			name: "imagen",
			label: "Imagen",
		},
	];
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data}></ModalForm>
		</>
	);
};

export default Categoria;
