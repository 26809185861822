export const READY = "READY";
export const LOADING = "LOADING";
export const LOADED = "LOADED";
export const LOAD_FAILED = "LOAD_FAILED";

export const SAVING = "SAVING";
export const SAVED = "SAVED";
export const SAVE_FAILED = "SAVE_FAILED";

export const INSERTING = "INSERTING";
export const INSERTED = "INSERTED";
export const INSERT_FAILED = "INSERT_FAILED";

export const UPDATING = "UPDATING";
export const UPDATED = "UPDATED";
export const UPDATE_FAILED = "UPDATE_FAILED";

export const DELETING = "DELETING";
export const DELETED = "DELETED";
export const DELETE_FAILED = "DELETE_FAILED";

export const LOAD = "LOAD_";
export const INSERT = "INSERT_";
export const UPDATE = "UPDATE_";
export const REMOVE = "REMOVE_";
export const TRASH = "TRASH_";
export const UNTRASH = "UNTRASH_";

export const COURSE = {url:"courses/", name:"COURSE", label:"Curso"};
export const CLASSES = {url:"classes/", name:"CLASS", label:"Clase"};
export const AUTHOR = {url:"authors/", name:"AUTHOR", label:"Autor"};
export const TASK = {url:"tasks/", name:"TASK", label:"Tarea"};
export const DOCUMENT = {url:"documents/", name:"DOCUMENT", label:"Documento"};
export const CATEGORY = {url:"categories/", name:"CATEGORY", label:"Categoria"};
export const SUBSCRIPTION = {url:"subscriptions/", name:"SUBSCRIPTION", label:"Suscripcion"};
export const DISCOUNT = {url:"discounts/", name:"DISCOUNT", label:"Descuento"};
export const FAQ = {url:"faq/", name:"FAQ", label:"Faq"};
export const HOME = {url:"home/", name:"HOME", label:"Home"};
export const COURSE_USER = {url:"course-user/", name:"COURSE_USER", label:"Curso usuario"};
export const PACKAGE = {url:"packages/", name:"PACKAGE", label:"Paquete"};
export const USER = {url:"users/", name:"USER", label:"Usuario"};
export const REPORT = {url:"reports/", name:"REPORT", label:"Reporte"};


export const makeStatusFromType = type => type.toUpperCase() + "_STATUS";
