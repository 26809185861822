import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import { useSelector } from "react-redux";
import FaqModal from "./Faq";
import {  FAQ, COURSE } from "store/constants";
import Header from "../../components/Header/Header";
import RefCell from "components/Table/CellTypes/RefCell/RefCell";
// comentario: Joi.string(),
// 	id_usuario: Joi.string(),
// 	id_clase: Joi.string(),
// 	publicado: Joi.boolean().when('__insert', { is: true, then: Joi.boolean().default(false) }),


const TabTareas = () => {
	const data = useSelector(state => state[FAQ.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Pregunta",
				accessor: "pregunta"
			},
			{
				Header: "Respuesta",
				accessor: "respuesta"
			}
		],
		[]
	);

	return (
		<>
			<Header title="Cursos" video time="5:35" />

			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={FAQ}
				modal={FaqModal}
				filters={columns}
				options={{ orderBy: true, disableDelete:true }}
			/>
		</>
	);
};

export default TabTareas;
