import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import { useSelector } from "react-redux";
import CursoUsuario from "./CursoUsuario";
import { REPORT } from "store/constants";
import Header from "../../components/Header/Header";
import RefCell from "components/Table/CellTypes/RefCell/RefCell";
import DateCell from "components/Table/CellTypes/DateCell/DateCell";
import FileCell from "components/Table/CellTypes/FileCell/FileCell";
import { COURSE, AUTHOR } from "store/constants";

const TabTareas = () => {
	const data = useSelector(state => state[REPORT.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Usuario",
				accessor: "id_autor",
				Cell:RefCell,
				type:AUTHOR,
				filter:"ref"
			},
			{
				Header: "Curso",
				accessor: "id_curso",
				Cell:RefCell,
				type:COURSE,
				filter:"ref"
			},
			{
				Header: "Desde",
				accessor: "desde",
				Cell:DateCell,
				filter:"date"
			},
			{
				Header: "Hasta",
				accessor: "hasta",
				Cell:DateCell,
				filter:"date"
			},
			{
				Header: "Total",
				accessor: "total",
			},
			{
				Header: "Reporte",
				accessor: "reporte",
				Cell:FileCell
			}
		],
		[]
	);

	return (
		<>
			<Header title="Reportes" video time="5:35" />

			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={REPORT}
				modal={CursoUsuario}
				filters={columns}
				options={{ orderBy: true }}
			/>
		</>
	);
};

export default TabTareas;
