import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { COURSE } from "../constants";

const loadCourses = loadRequest(COURSE);
const insertCourse = insertRequest(COURSE);
const updateCourse = updateRequest(COURSE);
const trashCourse = trashRequest(COURSE);
const untrashCourse = untrashRequest(COURSE);
const removeCourse = removeRequest(COURSE);

function* rootSaga() {
	yield all([
		loadCourses(),
		insertCourse(),
		updateCourse(),
		removeCourse(),
		trashCourse(),
		untrashCourse()
	]);
}
export default rootSaga;