import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { CATEGORY } from "../constants";

const loadCategories = loadRequest(CATEGORY);
const insertCategory = insertRequest(CATEGORY);
const updateCategory = updateRequest(CATEGORY);
const trashCategory = trashRequest(CATEGORY);
const untrashCategory = untrashRequest(CATEGORY);
const removeCategory = removeRequest(CATEGORY);

function* rootSaga() {
	yield all([
		loadCategories(),
		insertCategory(),
		updateCategory(),
		removeCategory(),
		trashCategory(),
		untrashCategory()
	]);
}
export default rootSaga;