import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as immutable from "object-path-immutable";
import PropTypes from "prop-types";

import useSkipFirstRender from "./useSkipFirstRender";
const _ = require("lodash");
var shortid = require("shortid");

let timerId = -1;
const useSave = (props) => {
	const [state, setState] = useState(props.data);
	const [changes, setChanges] = useState();
	const [status, setStatus] = useState({status:"LOADED"});
	const [isValid, setValid] = useState(true);
	//useSelector(state=>state[props.type?.name]?.view);

	useEffect(()=>{
		if(props.data || changes){
			setState(()=>{
				let data ={...props.data};
				_.merge(data, changes);
				return data;
			});
		}
	},[props.data]);
	useEffect(()=>{
		console.log(changes)
	},[changes]);
	
	const dispatch = useDispatch();

	const onChange = React.useMemo(
		() => (value, name) => {
			if (name === undefined) {
				setState(value);
				setChanges((state)=>({ ...state, ...value }));
			} else {
				setState(state=>{
					return immutable.set(state, name, value);
				});
				setChanges(state=>{
					return immutable.set(state, name, value);
				});
				// setChanges((state)=>({ ...state, [name]: value }));
			}
		},[]);
	
	useSkipFirstRender(()=>{
		console.log({changes});
		if(props.autoSave){
			
			const {_id, parent_id, ...rest} = changes;
			if(Object.keys(rest).filter(k=>{
				return rest[k]!==undefined;
			}).length>0){
				setStatus({status:"SAVING"});
				clearInterval(timerId);
				timerId = setInterval(saveData, 2000);
			}
		}
	},[changes]);

	
	const addFile = (files, field) => {

		if(Array.isArray(files.files)){
			const mappedFiles = files.files.map(file=>{
				const id=shortid.generate();
				console.log({aaaa:{
					name:field, 
					file:file, 
					_id:id 
				}});
				return {
					name:field, 
					file:file, 
					_id:id 
				};
			});

			setChanges((state)=>{
				return {
					...state, 
					"files": [...((state && state.files) || []), ...mappedFiles]
				};
			});
			setState((state)=>{
				return {
					...state, 
					"files": [...((state && state.files) || []), ...mappedFiles]
				};
			});
		}
		else{

			const id=shortid.generate();


			setChanges((state)=>{
				const f = ((state && state.files) || []).filter(f=>f.name!==field);
				return {
					...state, 
					"files": [...f, {
						name:field, 
						file:files.files,
						multiple:false,
						_id:id 
					}]
					
				};
			});
			setState((state)=>{
				const f = ((state && state.files) || []).filter(f=>f.name!==field);
				return {
					...state, 
					"files": [...f, {
						name:field, 
						file:files.files,
						multiple:false,
						_id:id 
					}]
					
				};
			});
		}
	};

	const removeFile = (file) => {
		const mappedFiles = changes.files.filter(f=>f.file != file);	
		setChanges((state)=>{
			return {
				...state, 
				"files": mappedFiles
			};
		});
		setState((state)=>{
			return {
				...state, 
				"files": mappedFiles
			};
		});
	};

	const remove = (idx, field, single) => {
		setChanges((state)=>immutable.del(state, `${field}.${idx}`));
		setState((state)=>immutable.del(state, `${field}.${idx}`));
	};

  
	const saveData = (options={}) => {

		clearInterval(timerId);

		let changesWithIds = {...changes, _id:props.data?._id, parent_id:props.parent_id};

		const uploadChanges = Object.keys(changesWithIds).reduce((acc, item)=>{
			_.set(acc, item, changesWithIds[item]);
			return acc;
		},{});
		const name = props.type.tail_name ? `${props.type.name}_${props.type.tail_name}` : props.type.name;

		setStatus({status:"SAVING"});



		if(options.saveAs || props.forceNew || !props.data){
			const uploads = {...state, parent_id:props.parent_id, fileUrl: props.fileUrl};
			dispatch({
				type: `INSERT_${name}`,
				payload: uploads,
				callback: (data)=>{
					setStatus({status:"INSERTED"});
					props.onInsert && props.onInsert(data.data);
				},
				errorCallback: (error)=>setStatus({status:"INSERT_FAILED", errorMsg:error}),
			});
		}
		else{
			const uploads = {...uploadChanges, parent_id:props.parent_id, _id:props.data._id, fileUrl: props.fileUrl};

			dispatch({
				type: `UPDATE_${name}`,
				payload: uploads,
				callback: (data)=>{
					setChanges({});
					setStatus({status:"UPDATED"});
					props.onUpdate && props.onUpdate(data.data);
				},
				errorCallback: (error)=>setStatus({status:"UPDATE_FAILED", errorMsg:error}),
			});
		} 
	};

	return {data:state, onChange, onFile:addFile, removeFile, remove, save:saveData, status, setValid, isValid};

};
export default useSave;

useSave.propTypes = {
	data: PropTypes.object,
	autoSave: PropTypes.bool,
	parent_id: PropTypes.string,
	onInsert: PropTypes.func,
	forceNew: PropTypes.bool,
	onUpdate: PropTypes.func,
	type: PropTypes.object,
	saveAs: PropTypes.bool
};