import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import ClaseModal from "./Clase";
import {CLASSES} from "store/constants";
const Curso = props => {

	const elements = React.useMemo(()=>[
		{
			type: "select",
			name: "status",
			label: "Status",
			options:[
				{value:"draft",label:"Draft"},
				{value:"edicion",label:"Edicion"},
				{value:"vivo",label:"Live"},
			]
		},
		{
			type: "text",
			name: "nombre",
			label: "Nombre",
		},
		{
			type: "ref",
			name: "id_categoria",
			label: "Categoria",
			selector:(state)=>state.CATEGORY.data.filter((r)=>!r._trash)

		},
		{
			type: "text",
			name: "descripcion_corta",
			label: "Descripcion corta",
		},
		{
			type: "text",
			name: "descripcion",
			label: "Descripcion",
			multiline: true
		},
		{
			type: "text",
			subtype: "number",
			name: "precio",
			label: "Precio",
		},
		{
			type: "file",
			name: "trailer",
			label: "Trailer",
			accept: "video/*"
		},
		{
			type: "file",
			name: "imagen",
			label: "Imagen",
		},
		{
			type:"multiref",
			name:"autores",
			label:"Autores",
			selector:(state)=>state.AUTHOR.data.filter((r)=>!r._trash)
		},
		{
			type: "select",
			name: "idioma",
			label: "Idioma",
			options:[
				{value:"espanol",label:"Español"}
			]
		},
		{
			type: "select",
			name: "nivel",
			label: "nivel",
			options:[
				{value:"basico",label:"Basico"}
			]
		},
		{
			type: "multiText",
			name: "incluye",
			label: "Incluye, max 10 caracteres",
		},
		{
			type: "text",
			name: "publico",
			label: "Publico",
		},
		{
			type: "text",
			name: "requisitos",
			label: "Requisitos",
		},
		{
			type: "bool",
			name: "curso_vivo",
			label: "En vivo",
		},
		{
			type: "if",
			name: ["curso_vivo","url_curso_vivo"],
			formula:(a)=>a,
			field:{
				type: "text",
				name: "url_curso_vivo",
				label: "Url curso vivo",
			}
		},
		{
			type: "if",
			name: ["curso_vivo","duracion"],
			formula:(a)=>a,
			field:{
				type: "text",
				name: "duracion",
				label: "Duracion",
			}
		},
		{
			type: "calendar",
			subtype:"datetime",
			name: "fecha_inicio",
			label: "Fecha de inicio",
		},
		{
			type: "multiText",
			name: "fuentes",
			label: "Fuentes",
		},
		{
			type: "richText",
			name: "texto_mail",
			label: "Texto mail",
		},
		{
			type: "multiGroup",
			name: "unidades",
			label: "Unidades",
			fields:[
				{
					type: "text",
					name: "nombre",
					label: "Nombre",
				},
				{
					type:"multiref",
					name:"clases",
					label:"Clases",
					selector:(state)=>state.CLASS.data.filter((r)=>!r._trash),
					modal:ClaseModal,
					modalType:CLASSES
				},
			]
		},
		{
			type:"text",
			subtype:"number",
			name:"p_comision",
			label:"Porcentaje comision Vitallier",
		},
		
		{
			type: "ref",
			name: "datos_pago",
			selector:(state)=>state.AUTHOR.data.filter((r)=>!r._trash),
			label: "Datos pago",
		},

	],[]);
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data} {...props}></ModalForm>
		</>
	);
};

export default Curso;
