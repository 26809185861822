import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { HOME } from "../constants";

const loadHomes = loadRequest(HOME);
const insertHome = insertRequest(HOME);
const updateHome = updateRequest(HOME);
const trashHome = trashRequest(HOME);
const untrashHome = untrashRequest(HOME);
const removeHome = removeRequest(HOME);

function* rootSaga() {
	yield all([
		loadHomes(),
		insertHome(),
		updateHome(),
		removeHome(),
		trashHome(),
		untrashHome()
	]);
}
export default rootSaga;