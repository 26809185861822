// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from "react";
import { Redirect, Route } from "react-router-dom";
export const PublicRoute = ({ component: Component, ...rest } ) => {

	const token = window.localStorage.getItem("plena:admin");
	const isLoggedIn =  !!token;
	
	const toLogin = rest.path === "/login";
	const func = (props) => {
		if(isLoggedIn && toLogin)return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;  
		else return <Component {...props} />;
	};
	return (
		<Route
			{...rest}
			render={func}
		/>
	);
};

export default PublicRoute;