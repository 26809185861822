import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { PACKAGE } from "../constants";

const loadPackages = loadRequest(PACKAGE);
const insertPackage = insertRequest(PACKAGE);
const updatePackage = updateRequest(PACKAGE);
const trashPackage = trashRequest(PACKAGE);
const untrashPackage = untrashRequest(PACKAGE);
const removePackage = removeRequest(PACKAGE);

function* rootSaga() {
	yield all([
		loadPackages(),
		insertPackage(),
		updatePackage(),
		removePackage(),
		trashPackage(),
		untrashPackage()
	]);
}
export default rootSaga;