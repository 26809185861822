import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import { DISCOUNT, COURSE } from "store/constants";
import { useSelector } from "react-redux";

// codigo: Joi.string(),
// nombre: Joi.string(),
// descripcion: Joi.string(),
// // precio: Joi.number(),
// // precio_descuento: Joi.number(),
// appstore_sku: Joi.string(),
// appstore_sku_suscriptores: Joi.string(),
// cursos:Joi.array().items(Joi.string()),
// fecha_inicio:Joi.date().iso(),
// fecha_fin:Joi.date().iso(),
// max_usos:Joi.number(),
// max_usos_cliente:Joi.number(),
// solo_clientes_nuevos: Joi.boolean(),
// automatico:Joi.boolean(),
// descuento:Joi.number(),
// p_descuento:Joi.number(),

const Categoria = props => {    
	const elements = [
		{
			type: "text",
			name: "nombre",
			label: "Nombre",
		},
		{
			type: "text",
			label: "Descripcion corta",
			name: "descripcion_corta",
		},	
		{
			type: "text",
			label: "Descripcion",
			name: "descripcion",
			multiline:true
		},	
		{
			type: "file",
			name: "imagen",
			label: "Imagen",
		},
		{
			type: "text",
			subtype:"number",
			name: "precio",
			label: "Precio",
		},
		{
			type:"multiGroup",
			label:"Cursos",
			name:"cursos",
			fields:[{
				type:"ref",
				selector:(state)=>state.COURSE.data.filter((r)=>!r._trash),
				label:"Curso",
				name:"id_curso"
			},{
				type:"text",
				subtype:"number",
				label:"Precio",
				name:"precio"
			}]
		}
		
	];

	// const {data, onChange, onFile, remove, save, status, isValid, setValid} = useSave({type:AUTHOR,data:sData, autoSave:true, fileUrl:"foto"});
	// console.log({sData,data})

	return (
		<>
			{/* <Header title="Bio" video time="5:35" /> */}
		
			{/* <AutoSaveStatus status={status} save={save} /> */}

			<ModalForm elements={elements} onChange={props.onChange} data={props.data} onFile={props.onFile}></ModalForm>
		</>
	);
};

export default Categoria;
