import React from "react";
import Header from "../../components/Header/Header";

import TablaCursos from "./TabCursos";
import TablaClases from "./TabClases";
import TablaTareas from "./TabTareas";
import TablaDocumentos from "./TabDocumentos";
import { useRouteMatch } from "react-router-dom";
import MyTabs from "components/Tabs/Tabs";


const Recetas = () => {
	let match = useRouteMatch();
	const tabs=[{
		label:"Cursos",
		value:"cursos",
		component:TablaCursos
	},{
		label:"Clases",
		value:"clases",
		component:TablaClases
	}];
	return (
		<>
			<Header title="Cursos" video time="5:35" />
			<MyTabs tabs={tabs} defSelected={match.params.seccion} urlKey="seccion" root={true} ></MyTabs>
		</>
	);

	// const {innerWidth} = useWindowSize();
	// const [value, setValue] = React.useState(0);
	// const [value2, setValue2] = React.useState(0);

	// const handleChange = (event, newValue) => {
	//   setValue(newValue);
	// };
	// const handleChange2 = (event, newValue) => {
	//   setValue2(newValue);
	// };
	// return (
	//   <>
	//     <Header title="Recetas" video time="5:35" />
	//     <Tabs
	//       value={value}
	//       onChange={handleChange}
	//       variant={innerWidth < 769 ? "scrollable" : 'fullWidth'}
	//       scrollButtons={innerWidth < 769 ? "on" : 'auto'}
	//       className={styles.flexContainer}
	//       indicatorColor="primary"
	//     >
	//       <Tab label="Recetario" />
	//       <Tab label="Categorias" />
	//       <Tab label="Sellos" />
	//     </Tabs>
	//     <TabPanel value={value} index={0}>
	//       <TablaRecetas />
	//     </TabPanel>
	//     <TabPanel value={value} index={1}>
	//       <TablaCategorias />
	//     </TabPanel>
	//     <TabPanel value={value} index={2}>
	//       <TablaSellos />
	//     </TabPanel>
	//   </>
	// );
};

export default Recetas;
