import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { REPORT } from "../constants";

const loadReports = loadRequest(REPORT);
const insertReport = insertRequest(REPORT);
const updateReport = updateRequest(REPORT);
const trashReport = trashRequest(REPORT);
const untrashReport = untrashRequest(REPORT);
const removeReport = removeRequest(REPORT);

function* rootSaga() {
	yield all([
		loadReports(),
		insertReport(),
		updateReport(),
		removeReport(),
		trashReport(),
		untrashReport()
	]);
}
export default rootSaga;