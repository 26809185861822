import React, { useState } from "react";
import { Modal, Fade, Button, CircularProgress, IconButton } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import styles from "Styles.module.scss";
import useSave from "Hooks/useSave";
import CloseIcon from '@material-ui/icons/Close';
import "./SaveModal.scss";


export default function SaveModal(props) {
	const onUpdate = id => {
		props.onUpdate && props.onUpdate(id);

		props.handleModal();
	};

	const onInsert = id => {
		props.onInsert && props.onInsert(id);
		props.handleModal();
	};

	const {data, onChange, onFile, remove, save, status, isValid, setValid, removeFile} = useSave({
		...props, 
		forceNew:props.forceNew, 
		onUpdate, 
		onInsert,
		parent_id:props.parent_id
	});

	const [openModal, setOpenModal] = useState(true);
	console.log({saveM:onFile})
	return (
		<>
			<Modal open={openModal} className={styles.modal}>
				<Fade in={openModal}>
					<div className={styles.paper} style={{width:props.fullWidth?"90%":""}}>
						<div style={{float:"right",marginTop:"-10px"}}>
							<IconButton onClick={props.handleModal}><CloseIcon /></IconButton>
						</div>
						<div style={{textAlign:"center", fontSize:"1.5em",fontWeight:"bold",marginBottom:"10px"}}>{props.type.label}</div>
						<props.modal setValid={setValid} {...props} a="a" remove={remove} removeFile={removeFile} onChange={onChange} onFile={onFile} data={data} />
						<br></br>
						<div className="buttons">
							{
								(status.status === "UPDATE_FAILED" || status.status === "INSERT_FAILED" ) &&
							<Alert severity="error">{JSON.stringify(status.errorMsg)}</Alert>
							}
						</div>
						<div className="container-buttons-save-modal">
							{ 
								(status.status === "SAVING") 
									?
									<CircularProgress />
									:
									<>
										<Button disabled={!isValid} color="primary" variant="contained" onClick={save}>
											{props.guardarLabel ? props.guardarLabel : "Guardar"}
										</Button>
										{(props.data && props.saveAs) &&
											<Button 
												variant="contained" 
												style={{backgroundColor:"#2D3C3A",color:"white"}} 
												onClick={()=>save({saveAs: true})}
											>
												Guardar como nuevo
											</Button>
										}
									</>
							}
							<Button onClick={props.handleModal}>Cerrar</Button>
						</div>
					</div>
				</Fade>
			</Modal>
		</>
	);
}

