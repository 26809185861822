import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import ClaseModal from "./Clase";
import { useSelector } from "react-redux";
import { CLASSES, COURSE } from "store/constants";

const TabClases = () => {
	const data = useSelector(state => state[CLASSES.name]);
	const [clases, setClases] = React.useState(data.data);

	const courseData = useSelector(state => state[COURSE.name].data);
	const getCurso = (idClase) => {
		for(let i=0;i<courseData?.length;i++){
			const curso = courseData[i];
			if(curso.unidades){
				for(let j=0;j<curso?.unidades?.length;j++){
					const unidad = curso?.unidades[j];
					for(let k=0;k<unidad?.clases?.length;k++){
						if(unidad.clases[k]===idClase)return curso.nombre;
					}
				}
			}
			
		}
	};
	React.useEffect(()=>{
		setClases(data.data.map(clase=>({...clase, nombre_curso:getCurso(clase._id)})));
	},[data, courseData]);
  
	const columns = React.useMemo(
		() => [
			{
				Header: "Nombre",
				accessor: "nombre",
			},
			{
				Header: "Curso",
				accessor: "nombre_curso",
			}
		],
		[]
	);


	return (
		<>
			<CrudTable
				data={clases}
				status={data.view}
				columns={columns}
				type={CLASSES}
				modal={ClaseModal}
				filters={columns}
				options={{ orderBy: true }}
			/>
		</>
	);
};

export default TabClases;
