import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { SUBSCRIPTION } from "../constants";

const loadCategories = loadRequest(SUBSCRIPTION);
const insertCategory = insertRequest(SUBSCRIPTION);
const updateCategory = updateRequest(SUBSCRIPTION);
const trashCategory = trashRequest(SUBSCRIPTION);
const untrashCategory = untrashRequest(SUBSCRIPTION);
const removeCategory = removeRequest(SUBSCRIPTION);

function* rootSaga() {
	yield all([
		loadCategories(),
		insertCategory(),
		updateCategory(),
		removeCategory(),
		trashCategory(),
		untrashCategory()
	]);
}
export default rootSaga;