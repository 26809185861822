import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { USER } from "../constants";

const loadUsers = loadRequest(USER);
const insertUser = insertRequest(USER);
const updateUser = updateRequest(USER);
const trashUser = trashRequest(USER);
const untrashUser = untrashRequest(USER);
const removeUser = removeRequest(USER);

function* rootSaga() {
	yield all([
		loadUsers(),
		insertUser(),
		updateUser(),
		removeUser(),
		trashUser(),
		untrashUser()
	]);
}
export default rootSaga;