import React, {useState} from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";

const Categoria = props => {  
	const elements = [
		{
			type: "text",
			subtype:"number",
			name: "orden",
			label: "Orden",
		},
		{
			type: "text",
			name: "nombre_seccion",
			label: "Nombre seccion",
		},
		{
			type: "multiref",
			name: "cursos",
			label: "Cursos",
			selector:(state)=>state.COURSE.data
		},
	];


	return (
		<>		
			<ModalForm elements={elements} onChange={props.onChange} data={props.data} onFile={props.onFile}>
				
			</ModalForm>
		</>
	);
};

export default Categoria;
