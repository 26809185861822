import { all } from "redux-saga/effects";

import authors from "./authors";
import categories from "./categories";
import classes from "./classes";
import courses from "./courses";
import documents from "./documents";
import tasks from "./tasks";
import subscriptions from "./subscriptions";
import discounts from "./discounts";
import faq from "./faq";
import home from "./home";
import courseUser from "./courseUser";
import user from "./user";
import packages from "./packages";
import reports from "./reports";

// classes(),
function* rootSaga() {
	yield all([authors(), categories(), classes(),  courses(), documents(), tasks(),subscriptions(),faq(),home(),courseUser(),user(),discounts(),packages(),reports()]);
}
export default rootSaga;
