import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { DOCUMENT } from "../constants";

const loadDocuments = loadRequest(DOCUMENT);
const insertDocument = insertRequest(DOCUMENT);
const updateDocument = updateRequest(DOCUMENT);
const trashDocument = trashRequest(DOCUMENT);
const untrashDocument = untrashRequest(DOCUMENT);
const removeDocument = removeRequest(DOCUMENT);

function* rootSaga() {
	yield all([
		loadDocuments(),
		insertDocument(),
		updateDocument(),
		removeDocument(),
		trashDocument(),
		untrashDocument()
	]);
}
export default rootSaga;