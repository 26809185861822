import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";

const Categoria = props => {    
	const elements = [
		{
			type: "text",
			name: "nombre",
			label: "Nombre",
		},
		{
			type: "text",
			name: "correo",
			label: "Correo",
		},
		{
			type: "text",
			name: "password",
			label: "Password",
		},
		{
			type: "text",
			name: "biografia",
			label: "Biografia",
			multiline:true
		},
		{
			type: "file",
			name: "foto",
			label: "Foto",
		},
		{
			type: "text",
			name: "datos_personales.nombre",
			label: "Nombre",
		},
		{
			type: "text",
			name: "datos_personales.apellido_paterno",
			label: "Apellido paterno",
		},
		{
			type: "text",
			name: "datos_personales.apellido_materno",
			label: "Apellido materno",
		},
		{
			type: "text",
			name: "datos_personales.telefono",
			label: "Telefono",
		},
		{
			type: "text",
			name: "datos_personales.rfc",
			label: "RFC",
		},
		{
			type: "text",
			name: "datos_personales.curp",
			label: "Curp",
		},
		{
			type: "text",
			name: "datos_personales.nombre_banco",
			label: "Nombre de banco",
		},
		{
			type: "text",
			name: "datos_personales.num_cuenta",
			label: "Numero de cuenta",
		},
		{
			type: "text",
			name: "datos_personales.cuenta_clabe",
			label: "Cuenta clabe",
		},
		{
			type: "text",
			name: "datos_personales.cp",
			label: "Codigo postal",
		}
	]
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} onFile={props.onFile} data={props.data}></ModalForm>
		</>
	);
};

export default Categoria;
