import React from "react";
import { IconButton} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
export default function FileCell({ cell }){
	return (
		<>
			<div>
				<a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_FILE_URL}${cell?.value}`} download><IconButton><GetAppIcon /></IconButton></a>
					
			</div>
		</>
	);
}