import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@material-ui/core";
import TabPanel from "components/TabPanel";
import { useHistory, useRouteMatch, generatePath } from "react-router-dom";
import styles from "Styles.module.scss";
import { useWindowSize } from "hooks";

export default function MyTabs(props){
	const {tabs, defSelected, urlKey, root, icon} = props;
	let match = useRouteMatch();
	let history = useHistory();
	const {innerWidth} = useWindowSize();
	const [selected, setSelected] = useState(defSelected ? +defSelected : false);


	const handleChange = (v) => {

		if(props.disableUrlParams){
			setSelected(v);
			return;
		}
		let prevParams = props.keys? props.keys.reduce((acc, val)=>{
			return {...acc, [val]: match.params[val] ? match.params[val] : 0};
		},{}) : {};

		prevParams[urlKey] = v;

		const path = generatePath(match.path, prevParams);
		history.push(path);


	};

	useEffect(()=>{

		if(!match.params[urlKey]){
			// handleChange(0);
		}

		if(!match.params[urlKey]){
			setSelected(0);
		}
		else
			setSelected(+match.params[urlKey]);


	},[match.params]);

  
	return <>
		<Tabs
			value={selected}
			onChange={(e,v)=>handleChange(v)}
			variant={innerWidth < 990 ? "scrollable" : "fullWidth"}
			scrollButtons={innerWidth < 769 ? "on" : "auto"}
			style={{marginBottom:"20px"}}
			className={styles.flexContainer}
			textColor="primary"
			indicatorColor="primary">
			{tabs.map((tab, idx)=>
				<Tab style={{height:root?"90px":"60px", fontWeight:"bold"}} key={tab.label} icon={icon ?
					<img
						className="image-tab"
						style={{height:"20px",objectFit:"contain"}}
						src={selected === idx ? tab.selectedImg : tab.img}
						alt="sección"
					/> : undefined
				} label={tab.label} />
			)}
		</Tabs>
		{tabs.map((tab, index)=>
			<React.Fragment key={index}>
				<TabPanel value={selected} index={index}>
					{props.children}
					<tab.component {...tab.props} />
				</TabPanel>
			</React.Fragment>
		)}
	</>;
};