import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { COURSE_USER } from "../constants";

const loadCourseUsers = loadRequest(COURSE_USER);
const insertCourseUser = insertRequest(COURSE_USER);
const updateCourseUser = updateRequest(COURSE_USER);
const trashCourseUser = trashRequest(COURSE_USER);
const untrashCourseUser = untrashRequest(COURSE_USER);
const removeCourseUser = removeRequest(COURSE_USER);

function* rootSaga() {
	yield all([
		loadCourseUsers(),
		insertCourseUser(),
		updateCourseUser(),
		removeCourseUser(),
		trashCourseUser(),
		untrashCourseUser()
	]);
}
export default rootSaga;