import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import { useSelector } from "react-redux";
import CursoUsuario from "./CursoUsuario";
import { COURSE_USER } from "store/constants";
import Header from "../../components/Header/Header";
import RefCell from "components/Table/CellTypes/RefCell/RefCell";
import { COURSE, USER } from "store/constants";

const TabTareas = () => {
	const data = useSelector(state => state[COURSE_USER.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Usuario",
				accessor: "id_usuario",
				Cell:RefCell,
				type:USER
			},
			{
				Header: "Curso",
				accessor: "id_curso",
				Cell:RefCell,
				type:COURSE
			}
		],
		[]
	);

	return (
		<>
			<Header title="Cursos" video time="5:35" />

			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={COURSE_USER}
				modal={CursoUsuario}
				filters={columns}
				options={{ orderBy: true }}
			/>
		</>
	);
};

export default TabTareas;
