import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import CursoModal from "./Curso";
import { useSelector } from "react-redux";
import { COURSE, AUTHOR } from "store/constants";
import RefCell from "components/Table/CellTypes/RefCell/RefCell";
const TabCursos = () => {
	const data = useSelector(state => state[COURSE.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Nombre",
				accessor: "nombre",
			},
			{
				Header: "Autores",
				accessor: "autores",
				Cell:RefCell,
				type:AUTHOR
			},
			{
				Header: "Status",
				accessor: "status",
			}
		],
		[]
	);

	return (
		<>
			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={COURSE}
				modal={CursoModal}
				filters={columns}
				options={{ orderBy: true }}
			/>
		</>
	);
};

export default TabCursos;
