import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";

// nombre: Joi.string(),
// 	intervalo: Joi.string().allow("mensual","anual"),
// 	stores_id: Joi.string(),
// 	n_creditos_intervalo: Joi.string(),
// 	precio: Joi.string(),
const Categoria = props => {    
	const elements = [
		{
			type: "text",
			name: "nombre",
			label: "Nombre",
		},
		{
			type: "select",
			name: "intervalo",
			label: "Intervalo",
			options:[
				{value:"month",label:"Mensual"},
				{value:"anual",label:"Anual"},
			]
		},
		{
			type: "text",
			name: "stores_id",
			label: "ID tienda",
		},
		{
			type: "text",
			subtype:"number",
			name: "n_creditos_intervalo",
			label: "# creditos intervalo",
		},
		{
			type: "text",
			subtype:"number",
			name: "precio",
			label: "Precio",
		}
	];
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data}></ModalForm>
		</>
	);
};

export default Categoria;
