import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { AUTHOR } from "../constants";

const loadAuthors = loadRequest(AUTHOR);
const insertAuthor = insertRequest(AUTHOR);
const updateAuthor = updateRequest(AUTHOR);
const trashAuthor = trashRequest(AUTHOR);
const untrashAuthor = untrashRequest(AUTHOR);
const removeAuthor = removeRequest(AUTHOR);

function* rootSaga() {
	yield all([
		loadAuthors(),
		insertAuthor(),
		updateAuthor(),
		removeAuthor(),
		trashAuthor(),
		untrashAuthor()
	]);
}
export default rootSaga;