
import React, {useState, useRef,useEffect} from "react";
// import Creatable, { makeCreatableSelect } from "react-select/creatable";
import { components } from "react-select";
import SaveModal from "components/SaveModal/SaveModal";

import {Button} from "@material-ui/core";

import { useSelector } from "react-redux";
import Select from 'react-select/creatable';
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import "./style.css";

const SortableSelect = SortableContainer(Select);
// const NoPaddingAutocomplete = withStyles({
//   inputRoot: {
//     '&&[class*="MuiOutlinedInput-root"]': {
//       padding: 1
//     }
//   },
//   input: {}
// })(Autocomplete);
function arrayMove(array, from, to) {
	array = array.slice();
	array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
	return array;
}

const SortableMultiValue = SortableElement(props => {
	// this prevents the menu from being opened/closed when the user clicks
	// on a value to begin dragging it. ideally, detecting a click (instead of
	// a drag) would still focus the control and toggle the menu, but that
	// requires some magic with refs that are out of scope for this example
	const onMouseDown = e => {
		e.preventDefault();
		e.stopPropagation();
	};
	const innerProps = { onMouseDown };
	return <components.MultiValue {...props}  innerProps={innerProps} />
});

const Text = props => {
	const [value, setValue] = useState();
	const [data, setData] = useState(null)
	let options = useSelector(eval(props.selector));
	options = options?.filter(o=> o._id && !o._trash && o.nombre ) || [];
	options = options?.sort((rowA, rowB) => {
		let a = rowA.nombre;
		let b = rowB.nombre;
		a = a ? a.toLowerCase() : a;
		b = b ? b.toLowerCase() : b;
		return a === b ? 0 : a > b ? 1 : -1;
	});

	useEffect(()=>{
		console.log({propsvalue:props.value})

		setValue(props.value?.map(v=>options.find(o=>o._id===v)).filter(v=>v) || []);
	},[props.value]);
	const onSortEnd = ({ oldIndex, newIndex }) => {
		const newValue = arrayMove(value, oldIndex, newIndex);
		// setSelected(newValue);
		handleChange(newValue);
		// console.log('Values sorted:', newValue.map(i => i.value));
	};
	

	const handleChange = (value) =>  {

		props.onChange(value.map(o=>o._id), props.name);
	};
	const handleClick = (e, data) => {
		console.log({data});
		e.preventDefault();
		e.stopPropagation();
		
		// selectRef.current.blur();
		const d = options.find(o=>{
			return o._id===data._id;
		});
		setData(d);
		setOpenModal(true);
	};
	
	const [openedModal, setOpenModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const handleCreate = (val)=>{
		console.log(val);
		setData(null);
		setOpenModal(true);
		setIsLoading(true);
	};

	const selectRef = React.useRef();

	const com = React.useMemo(()=>{
		return {
			MultiValueLabel:function MultiValueLabel(props){
				return <div onClick={(e)=>handleClick(e, props.data)} style={{width:'100%'}}>
					<components.MultiValueLabel {...props} >
						<div>{props.data.nombre}- {props.data.tipo}</div>
						{/* <div></div> */}
					</components.MultiValueLabel>
				</div>;
			},
			MultiValue: SortableMultiValue,
		};
	});
	return <>
		<div style={{display:'flex',flexDirection:'row'}}>
			<Button style={{float:'right',marginBottom:10}} color="primary" variant="contained" disabled={props.maxRows<=data?.length && props.maxRows!==-1}  onClick={handleCreate}>
				{props.addLabel ?props.addLabel : `Crear ${props.modalType?.label}`}
			</Button>
		</div>
		<SortableSelect
			axis="xy"
			// formatCreateLabel={()=>"Crear nuevo episodio"}
			distance={4}
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
			onSortEnd={onSortEnd}
			// hideSortableGhost={false} 
			helperClass="draggable-dragging"
			// style={{display:'block'}}
			isClearable={false}
			isDisabled={isLoading}
			isLoading={isLoading}
			onChange={handleChange}
			isMulti
			onCreateOption={handleCreate}
			options={options}
			value={value}
			menuPosition="fixed"
			ref={selectRef}
			// openMenuOnClick={false}
			components={com}
			getOptionLabel={(o)=> o.nombre}
			getOptionValue={(o)=>o._id}
			// isValidNewOption={(a,b)=>{
			// 	return true;
			// }}
			// getNewOptionData={(inputValue, optionLabel) => ({
			// 	_id: inputValue,
			// 	nombre: optionLabel,
			// })}
			// onChange={(option, { action }) => {
			// 	switch(action) {
			// 		case 'select-option':
			// 			handleChange(option);
			// 			break;
			// 	}
			// }}
			// getNewOptionData={console.log}
			// menuShouldScrollIntoView
		/>
		{openedModal && 
			<SaveModal 
				// {...props.options} 
				fullWidth={false} 
				onInsert={(d)=>{
					setIsLoading(false);
					handleChange([...value, d]);
					
				}} 
				modal={props.modal} 
				// forceNew={forceNew}
				type={props.modalType} 
				data={data}
				// status={props.status} 
				handleModal={()=>{
					setIsLoading(false);
					setOpenModal(false);
					// const name = type.tail_name ? `${type.name}_${type.tail_name}` : type.name;
					// dispatch({
					// 	type: `${name}_STATUS`,
					// 	payload: {status:"LOADED"}
					// });
				}} />
			}

	</>

	// return <Autocomplete
	// 	id="combo-box-demo"
	// 	size="small"
	// 	multiple
	// 	defaultValue={props.value}
	// 	disableCloseOnSelect
	// 	options={options.map(o=>o._id)}
	// 	getOptionLabel={option => getLabel(option)}
	// 	renderOption={(option,{selected}) => (
	// 		<>
	// 			<React.Fragment>
	// 				<Checkbox
	// 					icon={icon}
	// 					checkedIcon={checkedIcon}
	// 					style={{ marginRight: 8 }}
	// 					checked={selected}
	// 				/>
	// 				{getLabel(option)}
	// 			</React.Fragment>
	// 		</>
	// 	)}
	// 	style={{ width: "100%" }}
	// 	onChange={handleChange}
	// 	// renderTags={v=>{
	// 	// 	console.log(v)
	// 	// 	return <div>{v}</div>
	// 	// }}
	// 	renderInput={params => (
	// 		<>
	// 			<TextField
	// 				{...params}
	// 				variant="outlined"
	// 				fullWidth
	// 			/>
	// 		</>
	// 	)}
	// />;
    
};

export default Text;



