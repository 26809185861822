import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { FAQ } from "../constants";

const loadFaqs = loadRequest(FAQ);
const insertFaq = insertRequest(FAQ);
const updateFaq = updateRequest(FAQ);
const trashFaq = trashRequest(FAQ);
const untrashFaq = untrashRequest(FAQ);
const removeFaq = removeRequest(FAQ);

function* rootSaga() {
	yield all([
		loadFaqs(),
		insertFaq(),
		updateFaq(),
		removeFaq(),
		trashFaq(),
		untrashFaq()
	]);
}
export default rootSaga;