import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { CLASSES } from "../constants";

const loadClasses = loadRequest(CLASSES);
const insertClass = insertRequest(CLASSES);
const updateClass = updateRequest(CLASSES);
const trashClass = trashRequest(CLASSES);
const untrashClass = untrashRequest(CLASSES);
const removeClass = removeRequest(CLASSES);

function* rootSaga() {
	yield all([
		loadClasses(),
		insertClass(),
		updateClass(),
		removeClass(),
		trashClass(),
		untrashClass()
	]);
}
export default rootSaga;